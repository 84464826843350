import Icon from "@/components/base/Icon"

import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import useFeedbackForm, { MESSAGE_LENGTH_MAX } from "./useFeedbackForm"
import { Button, Input } from "@dilypse/mui/base";

const FeedbackForm = ({ submit, loading }) => {
    const { t } = useTranslation()
    const { control, messageLength, disableSubmit, handleSubmit, handleSuccessSubmit } = useFeedbackForm({
        submit,
    })
    return (
        <form onSubmit={handleSubmit(handleSuccessSubmit)}>
            <Stack flexDirection={"column"} gap={2}>
                <Stack flexDirection={"row"} gap={2} className="feedback-form-stack" data-testid="feedback-form">
                    <Controller
                        render={({ field }) => (
                            <Input label={t("FEEDBACK.STEP_TWO.NAME")} required showLabelStar={true} {...field} />
                        )}
                        control={control}
                        name="name"
                    ></Controller>

                    <Controller
                        render={({ field }) => (
                            <Input label={t("FEEDBACK.STEP_TWO.EMAIL")} required showLabelStar={true} {...field} />
                        )}
                        control={control}
                        name="email"
                    ></Controller>
                </Stack>
                <Box>
                    <Controller
                        render={({ field }) => (
                            <Input
                                multiline
                                required
                                showLabelStar={true}
                                max={MESSAGE_LENGTH_MAX}
                                label={t("FEEDBACK.STEP_TWO.MESSAGE")}
                                {...field}
                            />
                        )}
                        control={control}
                        name="message"
                    ></Controller>
                    <Stack flexDirection={"row"} justifyContent={"flex-end"} sx={{ padding: "3px 14px" }}>
                        <Typography component="span" sx={{ color: "action.active" }}>
                            {messageLength} / {MESSAGE_LENGTH_MAX}
                        </Typography>
                    </Stack>
                </Box>
                <Button
                    variant="contained"
                    type="submit"
                    data-testid="submit-review"
                    onClick={() => console.log("here")}
                    disabled={disableSubmit || loading}
                >
                    {!loading && <Icon name="send" />}
                    {loading && <CircularProgress size={16} />}
                    <Typography
                        component={"span"}
                        sx={{
                            marginLeft: "8px",
                            color: "#fff",
                        }}
                    >
                        {t("FEEDBACK.STEP_TWO.SUBMIT")}
                    </Typography>
                </Button>
            </Stack>
        </form>
    )
}

export default FeedbackForm
